<div id="form" *ngIf="!loading">
<div style="text-align: center;">
    <h1>Running Zones</h1>
   <form class="parent">
     <div id="inputForm">
     
         <mat-form-field>
           <input matInput placeholder="Average Heart Rate" [(ngModel)]="averageHeartRate"
               type="number" pattern="[0-9]*" name="averageHeartRate" (input)="HeartRateChange()" />
         </mat-form-field>
     
     </div>
   </form>
 </div>


 <div class="container">
    <div style="font-weight: bold;">Lactate Threshold Heart Rate</div>
    <div class="data">{{ runZone.threshold }}</div>
</div>

<table>
  <thead> 
    <tr>
      <th>Zone</th>
      <th>Heart Rate</th>
      <th class="percentage">% of LTHR</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor='let z of runZone.HeartRate' class="mainZones">
      <td class="label">{{z.name}}</td>
      <td class="data">{{z.minCalc}} - {{z.maxCalc}}</td>
      <td class="data percentage"> {{z.min | percent }} - {{ z.max | percent }}</td>
    </tr>
  </tbody>
</table>
</div>
<div class="loading" *ngIf="loading"> 
  Loading Please Wait....
</div>