
<p>
    <button mat-menu-item (click)="itemClick('swimming-test')">
        <mat-icon>pool</mat-icon>
        <span>Swimming Test</span>
    </button>
    <button mat-menu-item (click)="itemClick('cycle-test')">
        <mat-icon>directions_bike</mat-icon>
        <span>Cycling Test</span>
    </button>
    <button mat-menu-item (click)="itemClick('running-test')">
        <mat-icon>directions_run</mat-icon>
        <span>Running Test</span>
    </button>
</p>
