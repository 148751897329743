<div id="form" *ngIf="!loading">
<form>
  <div id="inputForm">
  <mat-accordion>
    <mat-expansion-panel [expanded]="showInput">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Interval Times
        </mat-panel-title>
        <mat-panel-description>
          Enter in your interval times
        </mat-panel-description>
      </mat-expansion-panel-header>
    <div class="container" >
     <div class="item">
       <mat-form-field>
         <input matInput placeholder="Interval 1 (0:00.00)" [(ngModel)]="swim1" 
                type="text" [pattern]="timePattern" name="swim1"  />
       </mat-form-field>
     </div>
 
     <div class="item"> 
       <mat-form-field>
         <input matInput placeholder="Interval 2 (0:00.00)" [(ngModel)]="swim2" 
                type="text" [pattern]="timePattern" name="swim2" />
       </mat-form-field>
     </div>

     <div class="item">
       <mat-form-field>
           <input matInput placeholder="Interval 3 (0:00.00)" [(ngModel)]="swim3" 
                  type="text" [pattern]="timePattern" name="swim3" />
       </mat-form-field>
     </div>
    </div>
    </mat-expansion-panel>
    </mat-accordion>


    <div class="container">
      <div class="item">
        <mat-form-field>
            <mat-select [(value)]="measurements" (selectionChange)="ChangeMeasurement()">
              <mat-option value="yards" ng-selected="true">Yards</mat-option>
              <mat-option value="meters">Meters</mat-option>
            </mat-select>
          </mat-form-field>
      </div>
      <div class="item" *ngIf="showInput">
        <button mat-button (click)="Calculate()">Calculate</button>
      </div>
      <div class="item" style="padding-top: 20px;">
        
        <mat-checkbox color="primary" [(ngModel)]="showLength" name="ShowLength">Show Length</mat-checkbox>
        
      </div>
    </div>
    
 
 
  </div>
 </form>



 <div *ngIf="showTable" id="tableMain">
  <div class="container">
      <div style="font-weight: bold;">Threshold</div>
      <div class="data">
        {{ swimZones.threshold | transferToTime }}&nbsp;&nbsp;/&nbsp;
        <small>100 {{measurements}}</small
      ></div>
  </div>
  <br />

    <div *ngIf="!showLength">
    <table [style.width.px]="width" class="ZoneTable">
      <thead> 
        <tr>
          <th>Zone</th>
          <th>Pace</th>
          <th class="percentage">% of Threshold</th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor='let z of swimZones.Pace' class="mainZones">
          <td class="label">{{z.name}}</td>
          
          <td class="data">{{z.minCalc | transferToTime }} - {{z.maxCalc | transferToTime }}
              <span>&nbsp;&nbsp;/&nbsp;&nbsp;<small>100 {{measurements}}</small></span>
          </td>
          <td class="data percentage"> {{z.min | percent }} - {{ z.max | percent }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="container">
    <div *ngIf="showLength" class="lengthTable">
      <div class="row">
        <div class="header cell">Length</div>
        <div *ngFor="let z of swimZones.Pace" class="header cell" > 
          {{z.name}}
        </div>
      </div>
      <!-- 25 -->
      <div class="row" *ngFor="let l of lengths">
        <div class="data cell1">{{l}}</div>
        <div *ngFor="let z of swimZones.Pace" class="data cell">
          {{(z.minCalc * (l / 100)) | transferToTime }} - {{(z.maxCalc  * (l / 100)) | transferToTime }}
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<div id="loading" *ngIf="loading">
  Loading Please Wait.....
</div>