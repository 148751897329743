 
<div *ngIf="loading">
    Please wait we are loading....
  </div>
<div id="mainForm" *ngIf="!loading">
 <div style="text-align: center; margin-bottom: 10px;" class="InputSection">
   <h1>Cycling Zones</h1>
  <form class="parent">
      <mat-accordion>
          <mat-expansion-panel [expanded]="showInput">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Average Power and Heart Rate
              </mat-panel-title>
              <mat-panel-description>
                Enter in your data
              </mat-panel-description>
            </mat-expansion-panel-header>
    <div id="inputForm">

        <mat-form-field>
            <input matInput placeholder="Average Power" [(ngModel)]="averagePower" 
                  type="number" pattern="[0-9]*" name="averagePower" (input)="AveragePowerChange()" />
        </mat-form-field>
    
        <mat-form-field>
          <input matInput placeholder="Average Heart Rate" [(ngModel)]="averageHeartRate"
              type="number" pattern="[0-9]*" name="averageHeartRate" (input)="HeartRateChange()" />
        </mat-form-field>
    
    </div>
  </mat-expansion-panel>
</mat-accordion>
  </form>
</div>

  <div>

    <div class="container">
      <div style="font-weight: bold;">Functional Threashold Power</div>
      <div class="data">{{ cyclingZone.ftp }}</div>
    </div>
    <div class="container">
        <div style="font-weight: bold;">Lactate Threshold Heart Rate</div>
        <div class="data">{{ cyclingZone.threshold }}</div>
    </div>

  <table>
      <thead> 
        <tr>
          <th>Zone</th>
          <th>Power</th>
          <th class="percentage">% FTP</th>
          <th>Heart Rate</th>
          <th class="percentage">% of LTHR</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor='let z of rows' class="{{z.class}}">
          <td class="label">{{z.name}}</td>
          <td class="data">{{z.powerMin}} - {{z.powerMax}}</td>
          <td class="data percentage"> {{z.powerMinPercent | percent }} - {{ z.powerMaxPercent | percent }}</td>
          <td class="data">{{ z.hrMin == undefined ? "" : z.hrMin }} - {{ z.hrMax == undefined ? "" : z.hrMax }}</td>
          <td class="data percentage">{{ z.hrMinPercent == undefined ? "" : (z.hrMinPercent | percent) }} - 
                                      {{ z.hrMaxPercent == undefined ? "" : (z.hrMaxPercent | percent) }}</td>
        </tr>
      </tbody>
    </table> 
</div>
</div>
