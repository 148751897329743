<!--The content below is only a placeholder and can be replaced.-->
<div id="toolbar">
<mat-toolbar>
    <span>{{ title }}</span>
    <span class="example-fill-remaining-space"></span>
    <button mat-raised-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="ItemClick('home')">
          <mat-icon>home</mat-icon>
          <span>Home</span>
        </button>
        <button mat-menu-item (click)="ItemClick('swimming-test')">
            <mat-icon>pool</mat-icon>
            <span>Swimming</span>
          </button>
        <button mat-menu-item (click)="ItemClick('cycle-test')">
          <mat-icon>directions_bike</mat-icon>
          <span>Cycle</span>
        </button>
        <button mat-menu-item (click)="ItemClick('running-test')">
          <mat-icon>directions_run</mat-icon>
          <span>Run</span>
        </button>

      </mat-menu>
  </mat-toolbar>
</div>
<div id="main">
  <div id="mainImage"></div>
 

 <router-outlet></router-outlet>
</div>


